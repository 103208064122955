export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/[[lang=lang]]/checkout": [~7,[2]],
		"/[[lang=lang]]/forgot-password": [8,[2]],
		"/[[lang=lang]]/login": [9,[2]],
		"/[[lang=lang]]/payment": [~10,[2]],
		"/[[lang=lang]]/payment/failure": [~11,[2]],
		"/[[lang=lang]]/payment/success": [~12,[2]],
		"/[[lang=lang]]/product/[slug]": [~13,[2]],
		"/[[lang=lang]]/register": [14,[2]],
		"/[[lang=lang]]/reset-password": [~15,[2]],
		"/[[lang=lang]]/support": [~16,[2]],
		"/[[lang=lang]]/u/account": [17,[2,3]],
		"/[[lang=lang]]/u/change-password": [18,[2,3]],
		"/[[lang=lang]]/u/invoices": [~19,[2,3]],
		"/[[lang=lang]]/u/payment-methods": [21,[2,3]],
		"/[[lang=lang]]/u/payment-methods/new-card/error": [~22,[2,3,4]],
		"/[[lang=lang]]/u/payment-methods/new-card/success": [~23,[2,3,4]],
		"/[[lang=lang]]/u/p/[phone]": [~20,[2,3]],
		"/[[lang=lang]]": [5,[2]],
		"/[[lang=lang]]/[slug]": [~6,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';